import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  cacheKey: []
};

const adviceCacheKeySlice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    setCacheKey(state, action) {
      let arrayValue = state.cacheKey.map((value: any) => Object.values(value)).flat();
      if (!arrayValue.includes(Object.values(action.payload)[0])) {
        state.cacheKey.push(action.payload);
      }
    },

    clearCacheKey(state) {
      state.cacheKey = null;
    }
  }
});

export const { setCacheKey, clearCacheKey } = adviceCacheKeySlice.actions;
export default adviceCacheKeySlice.reducer;
