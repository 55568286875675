import { Navigate } from 'react-router';
import { GuardProps } from 'types/auth';

const RequiredPermission = ({ children, level }: GuardProps) => {
  const UserLevel = localStorage.getItem('permission') || '';
  const parsedUserLevel = JSON.parse(UserLevel);
  const containsValue = parsedUserLevel.map((item: any) => item.value);
  if (containsValue.length > 0) {
    if (containsValue.includes(level)) {
      return children;
    } else {
      return (
        <div>
          <Navigate to="/"></Navigate>
        </div>
      );
    }
  } else {
    return (
      <div>
        <Navigate to="/"></Navigate>
      </div>
    );
  }
};

export default RequiredPermission;
